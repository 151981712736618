<template>
  <section class="es-close-form">
    <div class="xa-cell__box">关闭保障单原因</div>
    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell
          v-for="item in options"
          :title="item.label"
          clickable
          @click="radio = item.value"
          :key="item.value"
        >
          <van-radio slot="right-icon" :name="item.value" />
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="xa-cell__box">描述</div>
    <div class="xa-cell__box xa-bg-white">
      <textarea v-model="remark" rows="5" placeholder="请输入"></textarea>
    </div>
    <van-submit-bar
      :disabled="radio == -1 || !remark"
      button-text="确定"
      button-type="info"
    >
    </van-submit-bar>
  </section>
</template>
<script>
import { RadioGroup, Radio, Cell, CellGroup, SubmitBar } from 'vant'
export default {
  components: {
    VanRadioGroup: RadioGroup,
    VanRadio: Radio,
    VanCell: Cell,
    VanCellGroup: CellGroup,
    VanSubmitBar: SubmitBar,
  },
  data() {
    return {
      radio: -1,
      remark: '',
      options: [
        {
          label: '单据信息与设备不符',
          value: 1,
        },
        {
          label: '网点物料不足',
          value: 2,
        },
        {
          label: '未通过全保，且维修费用过高',
          value: 3,
        },
        {
          label: '其它',
          value: 4,
        },
      ],
    }
  },
}
</script>
<style lang="scss">
.es-close-form {
  .van-submit-bar__button {
    width: 100%;
  }
}
</style>
