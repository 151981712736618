<template>
  <section class="es-loss-material-form">
    <MaterialInfo :item="baseInfo" class="xa-com__space" />
    <div class="xa-cell__box xa-cell xa-bg-white">
      <div class="xa-flex">数量</div>
      <div><van-stepper v-model="count" min="1" /></div>
    </div>
    <div class="xa-cell__box">特殊情况</div>
    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell
          v-for="item in options"
          :title="item.label"
          clickable
          @click="radio = item.value"
          :key="item.value"
        >
          <van-radio slot="right-icon" :name="item.value" />
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="xa-cell__box">申请描述</div>
    <div class="xa-cell__box xa-bg-white">
      <textarea
        v-model="remark"
        rows="5" 
        placeholder="请描述丢失/二维码磨损情况，以便保障经理尽快核实理由"
      ></textarea>
    </div>
    <van-submit-bar button-text="确定" button-type="info">
      <div
        class="van-button van-button--danger van-button--large van-button--square van-submit-bar__button"
      >
        删除
      </div>
    </van-submit-bar>
  </section>
</template>
<script>
import { RadioGroup, Radio, Cell, CellGroup, SubmitBar, Stepper } from 'vant'
import MaterialInfo from '@/components/material/MaterialInfo'
export default {
  components: {
    VanRadioGroup: RadioGroup,
    VanRadio: Radio,
    VanCell: Cell,
    VanCellGroup: CellGroup,
    VanSubmitBar: SubmitBar,
    VanStepper: Stepper,
    MaterialInfo,
  },
  data() {
    return {
      baseInfo: {
        code: '14-001-00134',
        name: 'P20 2017折叠桨（反）',
        price: 330,
        interchange_type: '',
        is_single: '1',
        image:
          'http://agri-private.static.xag.cn/xservice/protection/materials/2019/07/04/2b783f208efa0ee272c8647c1b176596.jpg?imageView2/0/w/150/h/150&e=1877667205&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:9lTy2CLK6QlPwWmbmdzG-1w6Yhc=',
        source_image: [
          'http://agri-private.static.xag.cn/xservice/protection/materials/2019/07/04/2b783f208efa0ee272c8647c1b176596.jpg?e=1877604620&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:h2-MV-heRxekA7E3FYqwfE2Lz5A=',
        ],
        flag: ['LOSE'],
        serial_numbers: ['06682452', '64989988'],
      },
      radio: -1,
      remark: '',
      count: 1,
      options: [
        {
          label: '客户物料丢失',
          value: 1,
        },
        {
          label: '二维码磨损',
          value: 2,
        },
      ],
    }
  },
}
</script>
<style lang="scss">
.es-loss-material-form {
  .van-submit-bar__button {
    flex: 1;
  }
}
</style>
