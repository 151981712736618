<template>
  <section class="xa-container xa-container--hasFoot">
    <div class="xa-bg-white">
      <van-field
        v-model="description"
        rows="5"
        type="textarea"
        maxlength="200"
        placeholder="请描述客户使用情况、损坏现象。"
      />
    </div>
    <EnsureFootBtn
      :disabled="submitBtnDisabled"
      @submit="onSubmit"
      buttonText="保存并生成维修领料单"
    />
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import { Field } from 'vant'
import EnsureFootBtn from '@/components/EnsureFootBtn'
import { SubmitLossInfo } from '@/apis/protection'
import { commitLossMaterial } from '@/apis/material/'
export default {
  mixins: [basePage],
  config: {
    title: '诊断-诊断信息'
  },
  components: {
    EnsureFootBtn,
    VanField: Field
  },
  data() {
    return {
      description: ''
    }
  },
  computed: {
    submitBtnDisabled() {
      return this.description.length === 0
    }
  },
  methods: {
    checkSubmit(params) {
      let message = ''
      if (params.description.length === 0) {
        message = '定损描述信息'
      }
      if (message) {
        this.$notify({ type: 'danger', message })
        return false
      }
      return true
    },
    async submitData(params) {
      window.console.log(params)
      await this.$_request(SubmitLossInfo(params))
      await this.$_request(commitLossMaterial(this.$route.query))
    },
    async onSubmit() {
      const params = {
        ...this.$route.query,
        description: this.description
      }
      window.console.warn('onSubmit', params)
      if (this.checkSubmit(params)) {
        await this.$_submitDataWidthUi(
          () => this.submitData(params),
          `确定提交诊断结果`,
          `提示`,
          true
        )
        setTimeout(() => {
          this.$router.go(-2)
        }, 300)
      }
    }
  }
}
</script>
